import {HttpHandlerFn, HttpRequest, HttpEvent} from '@angular/common/http'
import {Observable} from 'rxjs'
import {SECRET} from '../../environments/secret'

/**
 * Function-based interceptor to add X-Api-Key dynamically based on the request URL.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  let headers = req.headers

  /**
   * Assign an X-Api-Key if the URL contains '/interests'
   */
  if (req.url?.indexOf('/interests') !== -1) {
    headers = headers.set('X-Api-Key', SECRET)
  }

  // Clone the request with updated headers
  const modifiedReq = req.clone({headers})

  // Pass the modified request to the next handler
  return next(modifiedReq)
}
