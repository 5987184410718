import {Component, OnInit} from '@angular/core'
import {filter} from 'rxjs/operators'
import {HemnetService, IHemnetData} from '../../services/hemnet.service'

@Component({
  selector: 'spb-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent implements OnInit {

  public monthlyCost: number = 0
  public loanAmount: number = 0
  public interest: number = 0
  public runCost: number = 0
  public amortization: number = 0
  public fee: number = 0
  public taxDeduction: number = 0
  public amortizationPercent: number = 0

  private rates = [
    {rate: 0.5, value: 0.01},
    {rate: 0.7, value: 0.02}
  ]

  constructor(
    private hemnetService: HemnetService
  ) {
  }

  ngOnInit(): void {
    this.hemnetService.hemnetData.pipe(
      filter((hemnetData: IHemnetData) => hemnetData && !!hemnetData.expectedPrice)
    ).subscribe(
      {
        next: (hemnetData: IHemnetData) => {
          hemnetData.runCost = hemnetData.runCost || 0
          hemnetData.fee = hemnetData.fee || 0
          const loanAmount = hemnetData.expectedPrice - hemnetData.downPayment
          const interestRate = hemnetData.interestRate / 100
          const yearlyInterest = loanAmount * interestRate
          const monthlyInterest = Math.ceil(yearlyInterest / 12)
          let monthlyTaxDeduction = Math.ceil(monthlyInterest * 0.30)
          if (yearlyInterest > 100000) {
            monthlyTaxDeduction = Math.ceil((30 * 1000 + ((yearlyInterest - (100 * 1000)) * 0.20)) / 12)
          }
          this.loanAmount = loanAmount
          this.fee = hemnetData.fee

          this.amortization = Math.ceil((hemnetData.expectedPrice - hemnetData.downPayment) *
            this.getAmortizationPercent(hemnetData.expectedPrice, hemnetData.downPayment) / 12)
          this.runCost = Math.ceil(hemnetData.runCost / 12)
          this.interest = monthlyInterest
          this.taxDeduction = monthlyTaxDeduction
          this.monthlyCost = this.fee + this.amortization + this.runCost + this.interest - this.taxDeduction
        }
      })
  }

  private getAmortizationPercent(price: number, downPayment: number): number {
    const degree = (price - downPayment) / price
    this.amortizationPercent = this.rates.reduce((current: number, rate: any) => degree > rate.rate ? rate.value : current, 0)
    return this.amortizationPercent
  }
}
