import {Component} from '@angular/core'
import version from '../../../assets/package.json'

@Component({
  selector: 'spb-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  public version = version.version
}

