import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'

import {AppComponent} from './app.component'
import {authInterceptor} from './application/auth.interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {HemnetCommonModule} from './common/common.module'
import {CalculationComponent} from './hemnet/calculation/calculation.component'
import {HemnetComponent} from './hemnet/hemnet.component'
import {InputComponent} from './hemnet/input/input.component'
import {ObjectInfoComponent} from './hemnet/object-info/object-info.component'

registerLocaleData(localeFr, 'fr', localeFrExtra)

@NgModule({
  declarations: [
    AppComponent,
    HemnetComponent,
    ObjectInfoComponent,
    CalculationComponent,
    InputComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HemnetCommonModule,
    ReactiveFormsModule,
    ThemeModule], providers: [
    WINDOW_PROVIDERS,
    provideHttpClient(withInterceptors([authInterceptor]))
  ]
})
export class AppModule {
}
