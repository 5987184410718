{
  "name": "hemnet-app",
  "version": "1.3.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "ng build",
    "test": "ng test --watch=false --code-coverage",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/forms": "^18.2.6",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "rxjs": "~7.8.0",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/language-service": "^18.2.6",
    "@sparbanken-syd/personnummer": "3.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.2.3",
    "eslint-plugin-prefer-arrow": "^1.2.2",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "^2.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "postcss": "^8.4.24",
    "postcss-scss": "^4.0.6",
    "protractor": "~7.0.0",
    "stylelint": "^15.7.0",
    "stylelint-config-standard-scss": "^9.0.0",
    "stylelint-scss": "^5.0.1",
    "ts-node": "~8.3.0",
    "typescript": "~5.5.4"
  }
}
