<spb-header class="header"></spb-header>
<div class="spb-holder mat-typography">
  <div class="title">
    <h1>Räkna på ditt boende</h1>
  </div>
  <spb-object-info class="object-info"></spb-object-info>
  <div class="calc-holder">
    <spb-input></spb-input>
    <spb-calculation></spb-calculation>
  </div>
  <div class="button-holder">
    <div class="button">
      <a [href]="calculatorUrl + '?' + calculatorQuery">Räkna på ditt boende</a></div>
    <div class="button">
      <a [href]="'https://www.sparbankensyd.se/lana-pengar/lanelofte/' + utmTags">Ansök om lånelöfte</a></div>
    <div class="button">
      <a [href]="'https://www.sparbankensyd.se/lana/bolan/ansokan-bolan' + utmTags">Ansök om bolån</a>
    </div>
  </div>
  <div class="disclaimer">
    {{ disclaimerText }}
  </div>
</div>
<div class="spb-filler"></div>
<spb-contact></spb-contact>
