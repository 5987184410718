<div class="holder">
  <div class="image"><img alt="image"
                          [src]="imageUrl"></div>
  <div class="data">
    <h3>{{name}}</h3>
    <p>Utropspris: {{askingPrice | number: '1.0-0' : 'fr' }}</p>
    <p>Boarea: {{area | number: '1.0-0' : 'fr'}} kvm</p>
    <p>Byggår: {{year}}</p>
    <p *ngIf="fee">Avgift: {{fee | number: '1.0-0' : 'fr'}} kr/mån</p>
  </div>
</div>
