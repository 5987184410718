<form [formGroup]="inputForm" class="holder">

  <div class="input">
    <label for="expectedPrice">Vad tror du slutpriset blir?</label>
    <div class="input-container" data-after-content="kr">
      <input formControlName="expectedPrice" id="expectedPrice" inputmode="numeric"
             spbFormatNumber>
    </div>
  </div>

  <div class="input">
    <label for="downPayment">Kontantinsats</label>
    <div class="input-container" data-after-content="kr">
      <input
        [ngClass]="{'error': downPaymentError}"
        formControlName="downPayment"
        id="downPayment"
        inputmode="numeric"
        spbFormatNumber>
    </div>
    <p [ngClass]="{'error': downPaymentError}">Kontantinsatsen är minst 15% ({{calculatedDownPayment}}) av bostadens
      pris</p>
  </div>

  <div class="input">
    <label for="interestRate">Ränta</label>
    <div class="input-container" data-after-content="%">
      <input formControlName="interestRate" type="number"
             class="percent" id="interestRate">
    </div>
    <p>Listränta för lån bundet på 3 månader</p>
  </div>

  <div class="input">
    <label for="fee">Månadsavgift</label>
    <div class="input-container" data-after-content="kr">
      <input formControlName="fee" id="fee" inputmode="numeric" spbFormatNumber>
    </div>
  </div>

  <div class="input">
    <label for="runCost">Driftskostnad per år</label>
    <div class="input-container" data-after-content="kr">
      <input formControlName="runCost" id="runCost" inputmode="numeric"
             spbFormatNumber>
    </div>
  </div>
</form>

<!--<button>SAVE</button>-->
