<div clasS="holder">
  <div class="month-cost">
    <div class="title">Månadskostnad:</div>
    <div class="amount">{{monthlyCost | number: '1.0-0' : 'fr'}} kr</div>
  </div>
  <div class="loan-amount">
    <div class="title">Lånebelopp:</div>
    <div class="amount">{{loanAmount | number: '1.0-0' : 'fr'}} kr</div>
  </div>
  <div class="details">
    <div class="title">Så här har vi räknat:</div>
    <div class="item">
      <div class="">Ränta</div>
      <div class="">{{interest | number: '1.0-0' : 'fr'}} kr</div>
    </div>
    <div class="item">
      <div class="">Amortering</div>
      <div class="">{{amortization | number: '1.0-0' : 'fr'}} kr</div>
    </div>
    <div *ngIf="runCost" class="item">
      <div class="">Driftskostnad</div>
      <div class="">{{runCost | number: '1.0-0' : 'fr'}} kr</div>
    </div>
    <div *ngIf="fee" class="item">
      <div>Månadsavgift</div>
      <div>{{fee | number: '1.0-0' : 'fr'}} kr</div>
    </div>
    <div class="item">
      <div class="">Skattereduktion</div>
      <div class="">-{{taxDeduction | number: '1.0-0' : 'fr'}} kr</div>
    </div>
    <div class="item">
      <div class="">Totalt</div>
      <div class="">{{monthlyCost | number: '1.0-0' : 'fr'}} kr</div>
    </div>
  </div>
</div>
