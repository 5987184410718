import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {switchMap} from 'rxjs/operators'
import {DisclaimerService, LatestValidInterest} from './disclaimer.service'


// I do not really understand why it complains about this. /Daniel 2020-11-23
// eslint-disable-next-line no-shadow
export enum PropertyType {
  NONE = -1,
  VILLA = 0,
  FRITIDSHUS,
  BOSTADSRATT
}


/**
 * A type for the received hemnet data.
 */
export interface IHemnetData {
  /**
   * The original hemnet objectId
   */
  hemnetId?: string

  /**
   * Sparbanken internal Id for the object
   */
  id?: string

  /**
   * The estimated price, or "utgångspris"
   * Could be a value edited by the user.
   */
  askingPrice: number

  /**
   * Expected price, what the user thinks it's going to be.
   */
  expectedPrice: number

  /**
   * Donw payment, the "cash" to deduct from the price/expected price
   */
  downPayment: number

  /**
   * Like villa, bostadsrätt, fritidshus.
   */
  type?: PropertyType

  /**
   * If property type = bostadsrätt this is mandatory.
   */
  fee: number

  /**
   * Run cost, per year as reported, or entered by the user.
   */
  runCost?: number

  /**
   * Interest rate, a number collected from RT Backend
   */
  interestRate: number

  /**
   * The interest rate used to calculate the disclaimer
   */
  disclaimerInterestRate: number

  /**
   * The start date of the latest valid interest, a number collected from RT Backend
   */
  interestStartDate?: number

  /**
   * Living area
   */
  area: number

  /**
   * Year of construction
   */
  year: number

  /**
   * Name
   */
  name: string

  /**
   * An image, actually an Image URL
   */
  image?: string
}

@Injectable({
  providedIn: 'root'
})
export class HemnetService {

  /**
   * Subscribe to this to get the latest info.
   * Publish here when you have updated data.
   * Publishers should make sure to send the complete, last received object.
   */
  public hemnetOutput: BehaviorSubject<IHemnetData> = new BehaviorSubject<IHemnetData>({} as any)

  public hemnetData: BehaviorSubject<IHemnetData> = new BehaviorSubject<IHemnetData>({} as any)

  /**
   * Variable to temporarily use iHemnetData
   */
  public iHemnetData: IHemnetData = {} as any

  constructor(
    private disclaimerService: DisclaimerService,
    private httpClient: HttpClient
  ) {
  }

  /**
   * Creates a cookie on the last two parts of the domain.
   */
  static CreateCookie(hemnetData: IHemnetData): void {
    const domain = window.location.hostname.split('.')
    domain.shift()
    const domainName = domain.join('.')
    const id = hemnetData.id
    const dateNumber = new Date().getTime() + 60 * 60 * 1000 // 1 hour
    const date = new Date(dateNumber)
    document.cookie = `hemnet=${id};SameSite=None;Secure=true;Domain=${domainName};Expires=${date.toUTCString()}`
  }

  /**
   * Fetch the hemnet data from our backend and publish the result.
   */
  getHemnetData(objectId: string): void {
    const url = `${environment.hemnetServiceUrl}/hemnet/${objectId}`
    const propertiesToConvert = ['downPayment', 'expectedPrice', 'runCost', 'fee', 'year']

    this.httpClient.get<IHemnetData>(url).pipe(
      switchMap((i: IHemnetData) => {
        propertiesToConvert.forEach(prop => {
          i[prop] = Number(i[prop])
        })

        this.iHemnetData = i

        /**
         * This is needed if getLatestInterest() fails
         */
        this.hemnetOutput.next(i)

        return this.disclaimerService.getLatestInterest()
      })
    )
      .subscribe({
        next: (i: LatestValidInterest) => {
          this.iHemnetData.interestRate = i.month3
          this.iHemnetData.disclaimerInterestRate = i.disclaimerInterest
          this.iHemnetData.interestStartDate = i.interestStartDate
          this.hemnetOutput.next(this.iHemnetData)
        }
      })
  }

  /**
   * Saves hemnet data on server
   */
  saveHemnetData(hemnetData: IHemnetData): Observable<IHemnetData> {
    const url = `${environment.hemnetServiceUrl}/loanpromise`
    return this.httpClient.put<IHemnetData>(url, hemnetData)
  }
}
